<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input
          class="w120 mb10 mr10"
          placeholder="分类名称"
          v-model="filterName"
        />
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="open"
          >+ 添加分类</el-button
        >
      </div>
    </div>
    <commonTable
      :tableData="tableData"
      @handleSelectionChange="handleSelectionChange"
      :total="total"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      :currentPage="currentPage"
      :loading="loading"
    >
      <template v-slot:table>
        <!-- <el-table-column type="selection" /> -->
        <el-table-column prop="goodstypeId" align="center" label="编号" />
        <el-table-column prop="name" align="center" label="分类名称" />
        <el-table-column prop="goodsNum" align="center" label="商品数量" />
        <el-table-column prop="dealNum" align="center" label="总销量" />
        <el-table-column prop="" align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，分类排序越靠前" placement="top-start">
              <el-input v-model="scope.row.sort" placeholder="数值越大，分类排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="primary" plain @click="open(scope.row)"
              >编辑</el-button
            >
            <customPopconfirm
              class="ma ml10 mb10"
              confirm-button-text="确定"
              cancel-button-text="取消"
              @confirm="del(scope.row)"
              title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
// @ts-ignore
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm";
// @ts-ignore
import {
  getGoodsTypeInfo,
  selectGoodsTypeInfoPC,
  addGoodsType,
  delGoodsType,
  updateGoodsTypeSort
  // @ts-ignore
} from "@/api/coupon.js";

export default {
  name: "productList",
  components: {
    commonTable,
    customPopconfirm
  },
  props:{
    jurisdiction:{
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      options: [{ label: "全部", value: 1 }],
      tableData: [],
      filterName: "",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading:false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
    };
  },

  methods: {
    //分类弹窗
    open(row) {
      if(this.userInfo.roleId || this.jurisdiction){
        this.row = row.name;
      //新增
      if (!this.row) {
        this.$prompt("请输入商品类型名称", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(({ value }) => {
            addGoodsType({
              name: value,
              sort: 0,
              isDel: 0,
            })
            .then(res=>{
              
              this.$message({
                type: "success",
                message: "添加成功",
                center: true,
              });
              this.get();
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "操作取消",
              center: true,
            });
          });
        } else {
          //编辑
          this.$prompt("请输入商品类型名称", {
            onConfirmButtonText: "确定",
            cancelButtonText: "取消",
            inputValue: row.name,
          })
          .then(async ({ value }) => {
            addGoodsType({
              name: value,
              sort: row.sort,
              isDel: row.isDel,
              goodstypeId: row.goodstypeId,
            });

            this.$message({
              type: "success",
              message: "编辑成功",
              center: true,
            });
            this.get();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "操作取消",
              center: true,
            });
          });
        }
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
    },
    handleSelectionChange(value) {
      this.delGoodsT = value;
    },
    //删除弹窗
    del(row) {
      if(this.userInfo.roleId || this.jurisdiction){
        delGoodsType({ goodstypeId: row.goodstypeId })
        .then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
            center: true,
          });
          this.get();
        })
        .catch(() => {
          this.$message({
            message: "操作失败",
            type: "warning",
            center: true,
          });
        });
      }else{
        this.$message({
          type: "warning",
          message: "暂无权限",
        });
        return
      }
    },

    //获取数据
    async get() {
      let data = {
        pageNum:this.pageNum,
        pageSize:this.pageSize
      }
      this.loading = true;
      selectGoodsTypeInfoPC(data) //获取分类数据
      .then(res=>{
        this.loading = false;
        this.tableData = res.data.pageInfo.list;
        this.total = res.data.pageInfo.total
      })
      .catch(err=>{
        this.loading = false;
      })
    },
    //搜索
    async filterNames() {
      this.tableData = this.tableData.filter(
        (item) => this.filterName == item.name
      );
      if (this.filterName.length < 1) {
        this.get();
      }
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.get();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.loading = true;
      this.get();
    },
    //全删除
    delAll() {
      this.delGoodsT.map((itme) => {
        delGoodsType({ goodstypeId: itme.goodstypeId });
      });
      if (this.delGoodsT.length > 0) {
        this.$message({
          message: "操作成功",
          type: "success",
          center: true,
        });
      }
      this.delGoodsT = [];
      this.get();
    },
    async saveSort(row){
      if(row && row.sort != ""){
        console.log(row)
        const result = await updateGoodsTypeSort(row);
        console.log("result:",result)
        this.$message({
          type: "info",
          message: "保存成功",
        });
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    }
  },

  //生命周期
  async created() {
    this.get();
  },
};
</script>

<style lang="scss" scoped></style>
